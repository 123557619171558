import React from 'react'
import { PropTypes } from 'prop-types'
import {
  ComparativePeriods,
  MetricTypes,
  PerformanceProfitTypes,
  PerformanceTypes,
} from 'utils/constants'
import { currentDayInZone } from 'utils/dateTime'
import { useTranslation } from 'react-i18next'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import classNames from 'classnames/bind'
import { Metric } from './components/metric'
import styles from './KeyMetrics.module.scss'

const { GROSS_PROFIT, NET_PROFIT, BUSINESS_PROFIT } = PerformanceProfitTypes
const { RETAIL_VOLUME, GROSS_MARGIN, NET_MARGIN } = PerformanceTypes
const { VOLUME, MARGIN, PROFIT, NUMBER, BUNKER_COMMISSION } = MetricTypes
const { THIS_DAY_LAST_WEEK } = ComparativePeriods

const cx = classNames.bind(styles)

const addBunkeredVolumeMetric = (hasBunkered, metrics, bunkeredVolume) => {
  if (hasBunkered) {
    metrics.push({
      title: 'Bunkered',
      ...bunkeredVolume,
      metricType: VOLUME,
      trendIndicator: THIS_DAY_LAST_WEEK,
    })
  }
  return metrics
}

const addBunkerCommissionMetric = (hasBunkered, metrics, bunkerCommission) => {
  if (hasBunkered) {
    metrics.push({
      title: 'Bunker commission today',
      ...bunkerCommission,
      metricType: BUNKER_COMMISSION,
    })
  }
  return metrics
}

const getMetrics = (metricsData, companyTimeZone, hasBunkered, t) => {
  const {
    transactionCount,
    performanceType,
    averageFillUp,
    consumerVolume,
    fuelCardVolume,
    bunkeredVolume,
    yesterday,
    sevenDayAverage,
    bunkerCommission,
    monthToDate,
    today,
  } = metricsData

  switch (performanceType) {
    case RETAIL_VOLUME: {
      const metrics = [
        {
          /*TODO revert wording when avg EOD is implemented on backend
          title: t('widgets.stationPerformance.averageDayEODVolume', {
            currentDayInZone: currentDayInZone(companyTimeZone),
          }),
          */
          title: `Same time on an average ${currentDayInZone(
            companyTimeZone
          )} volume`,
          value: today?.comparativeValue,
          metricType: VOLUME,
        },
        {
          title: t('widgets.stationPerformance.numberOfTransactionsToday'),
          ...transactionCount,
          metricType: NUMBER,
          trendIndicator: THIS_DAY_LAST_WEEK,
        },
        {
          title: t('widgets.stationPerformance.averageFillUpToday'),
          ...averageFillUp,
          metricType: VOLUME,
          trendIndicator: THIS_DAY_LAST_WEEK,
        },
        {
          title: t('widgets.stationPerformance.consumerVolumeToday'),
          ...consumerVolume,
          metricType: VOLUME,
          trendIndicator: THIS_DAY_LAST_WEEK,
        },
        {
          title: t('widgets.stationPerformance.fuelCardVolumeToday'),
          ...fuelCardVolume,
          metricType: VOLUME,
          trendIndicator: THIS_DAY_LAST_WEEK,
        },
      ]

      return addBunkeredVolumeMetric(hasBunkered, metrics, bunkeredVolume)
    }
    case GROSS_MARGIN: {
      const metrics = [
        {
          title: t('widgets.stationPerformance.yesterdaysGrossMargin'),
          ...yesterday,
          metricType: MARGIN,
        },
        {
          title: t('widgets.stationPerformance.lastSevenDayAvgGrossMargin'),
          ...sevenDayAverage,
          metricType: MARGIN,
        },
      ]

      return addBunkerCommissionMetric(hasBunkered, metrics, bunkerCommission)
    }
    case NET_MARGIN: {
      const metrics = [
        {
          title: t('widgets.stationPerformance.yesterdaysNetMargin'),
          ...yesterday,
          metricType: MARGIN,
        },
        {
          title: t('widgets.stationPerformance.lastSevenDayAvgNetMargin'),
          ...sevenDayAverage,
          metricType: MARGIN,
        },
      ]

      return addBunkerCommissionMetric(hasBunkered, metrics, bunkerCommission)
    }
    case NET_PROFIT: {
      const metrics = [
        {
          title: t('widgets.stationPerformance.yesterdaysNetProfit'),
          ...yesterday,
          metricType: PROFIT,
        },
        {
          title: t('widgets.stationPerformance.monthToDateNetProfit'),
          ...monthToDate,
          metricType: PROFIT,
        },
      ]

      return addBunkerCommissionMetric(hasBunkered, metrics, bunkerCommission)
    }
    case GROSS_PROFIT: {
      const metrics = [
        {
          title: t('widgets.stationPerformance.yesterdaysGrossProfit'),
          ...yesterday,
          metricType: PROFIT,
        },
        {
          title: t('widgets.stationPerformance.monthToDateGrossProfit'),
          ...monthToDate,
          metricType: PROFIT,
        },
      ]

      return addBunkerCommissionMetric(hasBunkered, metrics, bunkerCommission)
    }
    case BUSINESS_PROFIT: {
      const metrics = [
        {
          title: t('widgets.stationPerformance.yesterdaysBusProfit'),
          ...yesterday,
          metricType: PROFIT,
        },
        {
          title: t('widgets.stationPerformance.monthToDateBusProfit'),
          ...monthToDate,
          metricType: PROFIT,
        },
      ]

      return addBunkerCommissionMetric(hasBunkered, metrics, bunkerCommission)
    }
    /* istanbul ignore next */
    default:
      break
  }
}

const KeyMetrics = ({ metricsData, hasBunkered, hasMetricPermission }) => {
  const { companyTimeZone } = useCompanyTimeZone()
  const { t } = useTranslation()

  const keyMetrics = getMetrics(...metricsData, companyTimeZone, hasBunkered, t)

  return (
    <div className={cx('key-metrics-container')}>
      <div className={cx('key-metrics-title')}>
        {t('widgets.stationPerformance.keyMetricsTitle')}
      </div>
      <div className={cx('key-metrics')}>
        {keyMetrics.map((metric, i) => {
          return (
            <Metric
              key={i}
              metric={metric}
              hasMetricPermission={hasMetricPermission}
            />
          )
        })}
      </div>
    </div>
  )
}

KeyMetrics.propTypes = {
  metricsData: PropTypes.array.isRequired,
  hasBunkered: PropTypes.bool.isRequired,
  hasMetricPermission: PropTypes.bool.isRequired,
}

export { KeyMetrics }
