const translations = {
  ordinals: {
    one: 'st',
    two: 'nd',
    few: 'rd',
    many: 'th',
    zero: 'th',
    other: 'th',
  },
  widgets: {
    buysmartAnalysis: {
      days: 'Days',
      lowTankVolume: 'Low tank volume',
      max: 'MAX',
      notReadyTooltip:
        "We are currently learning your station and BuySmart is not ready just yet. It'll be available soon!",
      stockout: 'Stockout',
      title: 'BuySmart analysis',
      ullage: 'Ullage',
      willBeLowOn: 'Will be low on',
      willRunOutOn: 'Will run out on',
    },
    companyPerformance: {
      fallback:
        'This widget has encountered an error. Please reload the page. If the problem persists, please contact support.',
      grossMargin: 'Gross margin',
      grossMarginTooltip:
        'Gross margin = gross profit / total volume.\nWhere possible, we exclude bunker volume. Cost of fuel sold is derived via FIFO blended methodology. All figures ex VAT.',
      grossProfit: 'Gross profit',
      grossProfitTooltip:
        'Gross profit = total fuel sales - total cost of fuel sold.\nCost of fuel sold is derived via FIFO blended methodology. All figures ex VAT.',
      netMargin: 'Net margin',
      netMarginTooltip:
        'Net margin = net profit / total volume.\nWhere possible, we exclude bunker volume. Cost of fuel sold is derived via FIFO blended methodology. All figures ex VAT.',
      retailVolume: 'Retail volume',
      retailVolumeTooltip:
        'Retail volume of fuel sold (excluding bunker volume where applicable).',
      totalVolume: 'Total volume',
      totalVolumeTooltip:
        'Total volume of fuel dispensed (including bunker volume where applicable).',
    },
    competitorPricing: {
      ALL: 'All',
      CASH: 'CASH',
      CREDIT: 'CREDIT',
      away: 'away',
      bracketsPrice: '(Price)',
      cancelEdits: 'Cancel edits',
      cashDiscountDifferential: 'Cash discount',
      cash: 'Cash',
      competitorName: 'Competitor name',
      confirm: 'Confirm',
      confirmPriceChanges: 'Confirm price changes',
      credit: 'Credit',
      current: 'Current',
      currentlyInMultiplePriceChangeTooltip:
        'You are currently in multiple price change mode. All prices need to be reviewed and sent at once.',
      custom: 'Custom',
      daysAgoText: `{{daysAgo}}d ago @ {{timeValue}}`,
      daysAgoOneText: `Yday @ {{timeValue}}`,
      daysAgoZeroText: `Today @ {{timeValue}}`,
      deleteStrategy: 'Delete strategy',
      done: 'Done',
      drivingDistance: 'Driving Distance',
      editChanges: 'Edit changes',
      editPrice: 'Edit price',
      editPrices: 'Edit prices',
      enterSinglePrice: 'Enter single price?',
      from: 'From',
      grade: 'Grade',
      groupEditing: 'Group editing',
      immediately: 'Immediately',
      laterToday: 'Later today',
      new: 'New',
      now: 'Now',
      manualInputInvalidTooltip:
        'Price must include one decimal point and not be greater or smaller than 50% of the current price.',
      myStation: 'My Station',
      noCashCreditPrice: '(Price) - No cash/credit price',
      noCashDiscountTooltip:
        'This station does not offer cash discounted prices',
      noCompetitorsTitle: 'No competitors',
      noCompetitorsBody:
        'It looks like you don’t have any competitors set yet. Please contact us to get this setup.',
      noPrice: 'No price',
      noStationManagerAssignedToolTip:
        'No station manager currently assigned. Please contact support to assign one before trying to make a price change on this station.',
      oneAfterActioned: ' 1 hour after (if not actioned)',
      polePriceChangesSent: 'Pole price changes sent',
      priceChangesErrors: 'Price change errors',
      priceChangesErrorsMessage: 'Some pole price changes can’t be sent.',
      priceChangesSent: 'Price changes sent',
      priceChangesSentMessage:
        'Your pole price changes have now been sent to the station managers.',
      priceChangesToBeMadeAt: 'Price changes to be made at',
      priceChangesToBeMadeAtTooltip:
        'This allows you to tell the station manager(s) what time you would like the price change(s) to be made at',
      prices: 'Prices',
      priceOn: `{{timeValue}}`,
      priceOnAt: `{{dateValue}} @ {{timeValue}}`,
      priceTodayAt: `Today @ {{timeValue}}`,
      pricingStrategy: 'Pricing strategy',
      priceYesterday: `Yesterday`,
      priceYesterdayAt: `Yesterday @ {{timeValue}}`,
      save: 'Save',
      selectADayAndTime: 'Select a day and time',
      sendPolePriceChangeReminders: 'Send pole price change reminders:',
      sendPolePriceChangeRemindersTooltip:
        'Checking these will enable EdgePetrol to automatically send pole price change reminders after pole price change(s) should have been made',
      sendingPolePriceChanges: 'Sending pole price changes...',
      sharedContent: 'Edit {{pricingTypeCopy}}prices',
      singlePriceSelectorTooltip:
        'If you are unsure whether the price you are entering is a cash or credit price, select this option and just insert a ‘price’.',
      station: 'Station',
      stationLiveTooltip: 'Station live on EdgePetrol',
      statusIconTooltip: "This price hasn't been updated in over 7 days",
      threeHoursAfterChangesShouldBeMade:
        'Notify me 3 hours after (changes should have been made)',
      tomorrow: 'Tomorrow',
      title: 'Local competitor prices',
      mapLegend: 'Map Legend',
      lowerCompetitorPrice: 'Lower Competitor Price',
      matchedCompetitorPrice: 'Matched Competitor Price',
      higherCompetitorPrice: 'Higher Competitor Price',
      missingPriceData: 'No Price Data Available',
      favourite: 'Favourite'
    },
    copyright: 'Copyright © EdgePetrol',
    delivery: {
      lastDeliveryDate: 'Last delivery date',
      theLastDetectedDeliveryWasOn: 'The last detected delivery was on',
    },
    analytics: {
      allBenchmarkRegions: 'All benchmark regions',
      blendedCost: 'Blended Cost',
      blendedCostSold: 'Blended Cost Sold',
      bunkeringComissions: 'Bunkering Commissions',
      bunkeredVolume: 'Bunkered Volume',
      businessProfit: 'Business Profit',
      calendarWarning: 'The previous period needs to occur prior to the current period.',
      competitorAnalysis: 'Competitor price analysis',
      consumerVolume: 'Consumer Volume',
      costFees: 'Costs/Fees',
      costPrice: 'Cost Price',
      fuelCardVolume: 'Fuel Card Volume',
      grossMargin: 'Gross Margin',
      grossProfit: 'Gross Profit',
      grossSales: 'Gross Sales',
      margin: 'Margin',
      mapOfUK: 'Map of UK Regions',
      netMargin: 'Net Margin',
      netProfit: 'Net Profit',
      noRegionsSelected: 'No benchmark regions selected',
      viewMap: 'View Map',
      volume: 'Volume',
      periodAnalysis: 'Period-over-period analysis',
      portfolioBenchmark: 'Portfolio Benchmark',
      replacementCost: 'Replacement Cost',
      retailFillUp: 'Retail Fill Up',
      retailVolume: 'Retail Volume',
      retailTransactions: 'Retail Transactions',
      salesProfit: 'Sales/Profit',
      title: 'Analytics',
      totalFees: 'Total Fees',
      totalVolume: 'Total Volume'
    },
    errorBoundary: {
      subtitle: "Let's get you back on the right track.",
      errorBackgroundText: 'Whoops',
      errorButton: 'Reload',
      errorHeading: 'It seems that we have encountered an error.',
      notFoundBackgroundText: '404',
      notFoundButton: 'Take me back',
      notFoundHeading: 'This station seems to have a stock out!',
    },
    grade: {
      blended: 'Blended',
      blendedTooltip:
        'The blended cost and replacement cost are indicative based on the blend ratio for this grade.',
      indicative: 'based on the blend for this grade.',
    },
    header: {
      cashCreditTooltip:
        'Cash/Credit is disabled in multiple price change mode',
    },
    headTitle: {
      portfolio: 'Portfolio',
    },
    inactivityLogout: {
      messageP1: 'auto logout will take place if no action is selected within ',
      messageP2: '{{countDown}} seconds',
      areYouStillHere: 'Are you still here?',
      logout: 'Logout',
      remainLoggedIn: 'Stay logged in',
    },
    leadGradeIndicator: {
      tooltipText:
        'This indicates that <0>{{shortTitle}}</0> is a lead grade and a pricing differential is set',
    },
    notifications: {
      clearAll: 'Clear all',
      noNewNotifications: 'No new notifications',
      title: 'Notifications',
      whenYouReceiveNotifications:
        'When you receive notifications they will appear here.',
    },
    performanceReport: {
      all: 'all',
      allGradesSelected: 'all grades selected',
      allStationsSelected: 'all stations selected',
      atLeastOneGradeTooltip: 'You must have at least one grade selected',
      average: 'average',
      bunkeredVolOnly: 'bunkered vol. only',
      bunkeredVolume: 'bunkered volume',
      businessProfit: 'business profit',
      businessProfitAll: 'business profit',
      confirm: 'confirm',
      currentPeriod: 'current period',
      customPeriod: 'custom period',
      date: 'date',
      day: 'day',
      days: 'days',
      exportToCSV: 'Export to CSV',
      fileNameAll: '{{name}} performance reporting. {{dateInZone}}',
      fileNameCash: '{{name}} performance reporting (Cash). {{dateInZone}}',
      fileNameCredit: '{{name}} performance reporting (Credit). {{dateInZone}}',
      fileNamePortfolioAll: 'Portfolio performance reporting. {{dateInZone}}',
      fileNamePortfolioCash:
        'Portfolio performance reporting (Cash). {{dateInZone}}',
      fileNamePortfolioCredit:
        'Portfolio performance reporting (Credit). {{dateInZone}}',
      fuelCardVolOnly: 'fuel card vol. only',
      fuelCardVolume: 'fuel card volume',
      grossMargin: 'gross margin',
      grossProfit: 'gross profit',
      last: 'last',
      last4Weeks: 'last 4 weeks',
      last4WeeksVsPrevious4Weeks: 'last 4 weeks vs previous 4 weeks',
      last30Days: 'last 30 days',
      last30DaysVsPrevious30Days: 'last 30 days vs previous 30 days',
      last7Days: 'last 7 days',
      last7DaysVsPrevious7Days: 'last 7 days vs previous 7 days',
      currentMonthVslastMonth: 'current month vs last month',
      currentQuarterVsLastQuarter: 'current quarter vs last quarter',
      currentYearVslastYear: 'current year vs last year',
      currentMonth: 'current month',
      currentQuarter: 'current quarter',
      currentYear: 'current year',
      lastPeriod: 'last period',
      margin: 'margin',
      netMargin: 'net margin',
      netMarginAll: 'net margin',
      netProfit: 'net profit',
      netProfitAll: 'net profit',
      noGradesSelected: 'no grades selected',
      noStationsSelected: 'no stations selected',
      previous: 'previous',
      previousMonth: 'previous month',
      previousQuarter: 'previous quarter',
      previousYear: 'previous year',
      previousPeriod: 'previous period',
      profit: 'profit',
      retailVolume: 'retail volume',
      showFuelCards: 'show fuel cards',
      thisPeriod: 'this period',
      thisWeek: 'This week',
      thisWeekVsLastWeek: 'This week vs last week',
      title: 'performance reporting',
      to: 'To',
      total: 'total',
      totalVolOnly: 'total vol. only',
      totalVolume: 'total volume',
      volume: 'volume',
      week: 'week',
      weeks: 'weeks',
    },
    settings: {
      chatToSupport: 'Chat to support',
      customerPortal: 'uk-ireland',
      successCenter: 'Success center',
      signOut: 'Sign out',
    },
    sidebar: {
      comingSoon: '<0>Edge</0> <0>Analytics</0> coming soon!',
    },
    stationOverlay: {
      stationSelector: 'Station {{index}} of {{total}}',
    },
    stationPerformance: {
      averageDayEODVolume: 'Average {{currentDayInZone}} EOD volume',
      averageFillUpToday: 'Average retail fill up today',
      busProfit: 'Bus profit',
      businessProfit: 'Business profit',
      currentGrossMarginTarget: 'Current gross margin target',
      deleteTarget: 'Delete target',
      disabledTooltip:
        'You currently have cash selected. This means that your gross and net margin data is the same. To be able to view net margin data, please select both cash and credit, or credit.',
      consumerVolumeToday: 'Consumer volume today',
      fuelCardVolumeToday: 'Fuel card volume today',
      gradesTitle: 'Grades',
      grossMargin: 'Gross margin',
      grossMarginTargetTitle: 'Gross margin target',
      grossNetOrBusiness: 'Gross, net or business',
      grossOrNet: 'Gross or net',
      grossProfit: 'Gross profit',
      keyMetricsTitle: 'Key metrics',
      lastSevenDayAvgGrossMargin: 'Last 7d avg. gross margin',
      lastSevenDayAvgNetMargin: 'Last 7d avg. net margin',
      monthToDateBusProfit: 'Month-to-date business profit',
      monthToDateGrossProfit: 'Month-to-date gross profit',
      monthToDateNetProfit: 'Month-to-date net profit',
      netMargin: 'Net margin',
      netProfit: 'Net profit',
      numberOfTransactionsToday: 'Total number of transactions today',
      retailVolume: 'Retail vol',
      save: 'Save',
      targetMargin: 'Target margin',
      unknownPaymentTooltip: 'Payment method not received from POS',
      volumeByPaymentType: 'Volume by payment type (Total volume)',
      yesterdaysBusProfit: 'Yesterdays business profit',
      yesterdaysGrossMargin: 'Yesterdays gross margin',
      yesterdaysGrossProfit: 'Yesterdays gross profit',
      yesterdaysNetMargin: 'Yesterdays net margin',
      yesterdaysNetProfit: 'Yesterdays net profit',
    },
    stationSearch: { searchForStation: 'Search for a station' },
    stationSelector: {
      itemsLimit: 'You can select up to {{limit}} stations',
      selectAll: 'Select all',
      deselectAll: 'Deselect all',
    },
    stationsList: {
      above: 'above',
      apply: 'Apply',
      below: 'below',
      cancel: 'Cancel',
      currentPortfolioMargin: 'Current portfolio margin',
      currentPortfolioMarginErrorTooltip:
        'We are having trouble getting your current portfolio margin. Please reload the page.',
      downBy: 'down by',
      editFilter: 'Edit filter',
      filter: 'Filter',
      filterBy: 'Filter by',
      filterStations: 'Filter stations',
      grossMargin: 'Gross margin',
      grossProfit: 'Gross profit',
      group: 'Group',
      groupBy: 'Group by',
      is: 'is',
      isGreaterThan: 'is greater than',
      isLessThan: 'is less than',
      isNot: 'is not',
      netMargin: 'Net margin',
      noStationsFound: 'No stations found',
      noStationsText:
        'Unfortunately, none of your station data matches the filters you have applied.',
      operator: 'Operator',
      retailVolume: 'Retail volume',
      station: 'Station',
      stations: 'Stations',
      targetMargin: 'Target Margin',
      tomorrowMiddayEstimate: 'Tomorrow midday est.',
      tomorrowMiddayEstimateErrorTooltip:
        'We are having trouble getting your tomorrow midday estimated margin. Please reload the page.',
      tomorrowMiddayEstimateTooltip:
        'By weighting the impact of each fuel grade on your portfolio margin, this is what we estimate your margin will be tomorrow at midday based on your proposed price changes.',
      total: 'Total',
      totalVolume: 'Total volume',
      upBy: 'up by',
      value: 'Value',
      yesterdayEOD: 'Yesterday EOD',
    },
    stationsListPricing: {
      cancelChangesBody:
        'Are you sure you want to cancel your pole price changes?',
      cancelChangesCancel: "Don't cancel",
      cancelChangesConfirm: 'Yes, cancel',
      cancelChangesTitle: 'Cancel changes',
      cantSend: "Can't send",
      cashDiscount: 'Cash discount',
      changesError: 'Price change errors',
      changesSent: 'Pole price changes sent',
      contactSupport: 'Contact support now',
      current: 'Current',
      deselectCashOrCreditTooltip:
        'Please de-select cash or credit in order to enter edit pricing mode',
      editPrices: 'Edit prices',
      gradeDifferentials: 'grade differentials',
      new: 'New',
      noChangesTitle: 'No proposed price changes',
      noChangesBody:
        'It seems as though you haven’t proposed any price changes for the selected station(s).',
      noGradeDifferentials: 'has no grade differentials',
      noStationManagerAssignedToolTip:
        'No station manager currently assigned. Please assign one before trying to make a price change on this station.',
      pending: 'Pending',
      priceChangeTooltip:
        'The <0>orange</0> price indicates that there is a price change pending for this grade.',
      priceChangeTooltipActionAt:
        'The <0>orange</0> price indicates that there is a price change pending to be actioned at {{date}}',
      pricesErrorBody:
        'Some pole price changes can’t be sent. To review the errors, click continue.',
      pricesErrorConfirm: 'Continue',
      pricesErrorTitle: 'Price change errors',
      pricesSentBody:
        'Your pole price changes have now been sent to the station managers.',
      pricesSentConfirm: 'Done',
      pricesSentTitle: 'Price changes sent',
      sendingChanges: 'Sending pole price changes...',
      station: 'Station',
      stationsSelected: 'Stations selected',
      status: 'Status',
    },
    stationStatus: {
      heading: 'Connection Status',
      transaction: 'Transaction',
      transactionAtMessage: 'Last transaction received {{date}}',
      dip: 'Dip',
      dipAtMessage: 'Last dip received {{date}}',
      costPrice: 'Cost Price',
      costPriceAtMessage:
        'Latest cost price received and was effective from {{date}}',
      closed: 'CLOSED',
      online: 'ONLINE',
      idle: 'IDLE',
      offline: 'OFFLINE',
      daysAgoText: `{{daysAgo}} days ago @ {{timeValue}}`,
      daysAgoOneText: `yesterday @ {{timeValue}}`,
      daysAgoZeroText: `today @ {{timeValue}}`,
    },
    toast: {
      genericError:
        'Oops! Something went wrong.\nPlease reload the page and try again.',
    },
    trendIndicator: {
      averageDay: 'the same time on an average {{day}}',
      comparedWith: 'Compared with ',
      friday: 'Friday',
      monday: 'Monday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      thisTimeLastWeek: 'at the same time on this day last week',
      thisTimeYesterday: 'this time yesterday',
      thursday: 'Thursday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
    },
    versionManagement: {
      dismiss: 'Dismiss',
      refreshBody:
        "Sorry to intrude, but we have made some changes and we require you to refresh the page to make sure you're up to date.",
      refreshNow: 'Refresh now',
      refreshTitle: "We've made some changes!",
    },
    wetstockAnalysis: {
      blendedCost: 'Blended cost',
      daysAgo: `{{daysAgo}} days ago`,
      lastCostPriceTooltip: 'This cost price was last updated over ',
      margin: 'Margin',
      replacementCost: 'Replacement cost',
      title: 'Wetstock analysis',
    },
    widgetFallback: {
      errorMessage:
        'This widget has encountered an error. Please reload the page. If the problem persists, please contact support.',
      errorMessagePage:
        'This page has encountered an error. Please reload the page. If the problem persists, please contact support.',
      reload: 'Reload',
      whoops: 'Whoops!',
    },
    maintenance: {
      title: `We'll be back shortly!`,
      text:
        'We are currently undergoing some essential maintenance. Please bear with us, service will be resumed as soon as possible.',
      backgroundText: 'Back Shortly',
    },
    dropdown: {},
  },
}

export { translations }
